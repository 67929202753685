const animated_elements = document.querySelectorAll(".bg_element");

animated_elements.forEach((item) => {
  const elements = item.querySelectorAll(".animate");

  if (elements.length > 0) {
    function cycleActive() {
      const elements = item.querySelectorAll(".animate");

      let activeIndex = Array.from(elements).findIndex((el) => el.classList.contains("active"));

      elements[activeIndex].classList.remove("active");

      const nextIndex = (activeIndex + 1) % elements.length;

      elements[nextIndex].classList.add("active");
    }

    setInterval(cycleActive, 1000);
  }
});

const filterButtons = document.querySelectorAll(".filters_buttons .btn");
const planItems = document.querySelectorAll(".plan_body .plan_item");

if (filterButtons) {
  filterButtons.forEach((button) => {
    button.addEventListener("click", function () {
      filterButtons.forEach((btn) => btn.classList.remove("active"));
      this.classList.add("active");

      const filter = this.id;

      planItems.forEach((item) => {
        if (filter === "all" || item.getAttribute("data-type") === filter) {
          item.classList.remove("hidden");
        } else {
          item.classList.add("hidden");
        }
      });
    });
  });
}

const mobile_menu = document.querySelector("#mobile_toggle");
const mobile_menu_close = document.querySelector("#close_mobile");
const nav = document.querySelector("#nav");
const nav_items = document.querySelectorAll("#nav a");

if (nav_items && nav) {
    nav_items.forEach((item) => {
      item.addEventListener("click", function (event) {
        nav.classList.remove("active");
      });
    });
}

if (mobile_menu && mobile_menu_close && nav) {
  mobile_menu.addEventListener("click", function () {
    nav.classList.toggle("active");
  });
  mobile_menu_close.addEventListener("click", function () {
    nav.classList.toggle("active");
  });
}
